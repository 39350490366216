import * as React from 'react';
import { Link } from 'gatsby';

// styles
const pageStyles = {
  color: '#232129',
  padding: '96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
  marginTop: 60,
};

const autoRedirect = () => {
  setTimeout(() => {
    window.location.href = '/#home';
  }, 3000);
};

// markup
function Contact() {
  autoRedirect();
  return (

    <main style={pageStyles}>
      <title>Obrigado pela mensagem!</title>
      <h1 style={headingStyles}>Contato enviado!</h1>
      <p style={paragraphStyles}>
        Obrigado por entrar em contato conosco. Você será redirecionado para
        a página inicial em 3 segundos.
        <br />
        <Link to="/#home">Voltar a home</Link>
      </p>
    </main>
  );
}

export default Contact;
